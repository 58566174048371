import { useCallback, useState } from 'react';
import { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { deserializeAxiosError } from 'common/utils/error';
import { buildChatConversation } from './helpers';
import { getTenantBotType } from 'api/tenantSettingsApi/tenantSettingsApi.utils';
import { GenericError } from '@zarn/vendor/dist/saved-results';
import { Conversation } from '../Chat.types';
import { Nullable } from 'common/utils/assert';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { ChatContext, ChatMessageElement } from 'api/chatApi/chatApi.types';
import { captureException } from '@sentry/react';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { sseRequest } from 'api/sse';
import { serializeChatPayload } from 'api/chatApi/chatApi.utils';
import { ChatResponseForm } from '@zarn/vendor/dist/search';
import { requestChatAnswer } from 'api/chatApi/chatApi';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useSse } from 'common/hooks/useSse';

const sendSseRequest = async <T extends object>(
  payload: ChatResponseForm,
  onMessage: (message: ChatMessageElement | null) => void,
  conversation: Conversation<T>
) => {
  return new Promise<Conversation<T>>((resolve, reject) => {
    let finalMessage: ChatMessageElement | null = null;
    void sseRequest({
      // TODO: Move to constants
      url: 'https://api-staging.zeta-alpha.com/v0/service/chat/stream?tenant=zetaalpha',
      // TODO: use tenant
      payload: {
        ...payload,
        agent_identifier: 'test_agent',
      },
      onMessage: (message: { last_message: ChatMessageElement }) => {
        finalMessage = finalMessage
          ? {
              ...finalMessage,
              content: `${finalMessage.content}${message.last_message.content}`,
            }
          : message.last_message;
        onMessage(finalMessage);
      },
      onError: (err: Error) => {
        reject(err);
      },
      onFinish: async () => {
        resolve({
          ...conversation,
          messages: finalMessage
            ? [...conversation.messages, finalMessage]
            : conversation.messages,
        });
        finalMessage = null;
      },
    });
  });
};

const sendApiRequest = async <T extends object>(
  payload: ChatResponseForm,
  tenant: string,
  conversation: Conversation<T>
): Promise<Conversation<T>> => {
  const response = await requestChatAnswer(payload, tenant);

  return {
    ...conversation,
    messages: response.data.conversation,
  };
};

interface Props<T extends object = {}> {
  context: ChatContext;
  conversation: Nullable<Conversation<T>>;
  botParams?: Record<string, any>;
  onConversationChange: (
    conversation: Conversation<T>
  ) => Promise<NoteDetails | { content: string } | null>;
}

export const useSendChatMessage = <T extends object = {}>({
  context,
  conversation,
  botParams,
  onConversationChange,
}: Props<T>) => {
  const { tenant } = useParsedHostname();
  const { tenantSettings } = useAssertTenantSettings();
  const { isSseAvailable } = useSse();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<GenericError | null>(null);
  const [loadingMessage, setLoadingMessage] =
    useState<ChatMessageElement | null>(null);

  const send = useCallback(
    async (message: BotTypeMessage) => {
      const updatedConversations = buildChatConversation(message, conversation);

      await onConversationChange(updatedConversations);

      const tenantBotType = getTenantBotType(
        updatedConversations.botType,
        tenantSettings.chat
      );

      try {
        setError(null);
        setIsLoading(true);
        setLoadingMessage(null);

        const payload = serializeChatPayload({
          chatResponseForm: {
            context,
            conversation: updatedConversations.messages,
            botType: tenantBotType,
            botParams: botParams,
          },
        });

        const responseConversation = isSseAvailable
          ? await sendSseRequest(
              payload,
              setLoadingMessage,
              updatedConversations
            )
          : await sendApiRequest(payload, tenant, updatedConversations);

        await onConversationChange(responseConversation);
        setIsLoading(false);
        setLoadingMessage(null);
        return responseConversation;
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
        setLoadingMessage(null);
      }
    },
    [
      conversation,
      onConversationChange,
      tenantSettings.chat,
      context,
      botParams,
      isSseAvailable,
      tenant,
    ]
  );

  const sendMessage = useCallback(
    async (botMessage: BotTypeMessage) => {
      try {
        setIsLoading(true);
        setError(null);

        return await send(botMessage);
      } catch (err) {
        captureException(err);
        setError(deserializeAxiosError(err));
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [send]
  );

  return { isLoading, error, sendMessage, loadingMessage };
};
