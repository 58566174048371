import React, { FC } from 'react';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';

import { Evidence } from 'api/chatApi/chatApi.types';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectUser } from 'containers/User/user.slice';

interface EvidenceDocProps {
  items: Evidence[];
  doc: RetrievalUnitData;
  i: number;
}

export const EvidenceDoc: FC<EvidenceDocProps> = ({ items, doc, i }) => {
  const userSettings = useSelector(selectUser);

  const item = items.find((hit) => hit.documentId === doc.document.id);
  if (!item) return null;

  const textExtract = item.textExtract;
  const replacedTextExtract = textExtract
    .replace(/<b>/g, '<em>')
    .replace(/<\/b>/g, '</em>');

  const data = {
    ...doc,
    highlight: replacedTextExtract,
    abstractContent: '',
  };

  return (
    <Box key={doc.document.id}>
      <RetrievalUnit
        data={data}
        key={doc.document.id}
        evidenceNumber={i + 1}
        withImage={true}
        cardComposition={userSettings?.cardStyle}
        isEvidence={true}
      />
    </Box>
  );
};
