import React, { FC, useMemo } from 'react';
import { useEvidenceHit } from '../../hooks/useEvidenceHit';
import { Parser } from '../../hooks/useConversation/parsers/Parser';
import { defined, isExist } from 'common/utils/assert';

import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { EvidenceContent } from './EvidenceContent';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface EvidenceItemProps {
  evidence: string;
  documentHitUrl?: string;
  label: string;
  onChunkClick?: (chunks: ChunkHighlight[]) => void;
  onEvidenceItemClick?: () => void;
}

export const EvidenceItem: FC<EvidenceItemProps> = ({
  evidence,
  documentHitUrl,
  label,
  onChunkClick,
  onEvidenceItemClick,
}) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const isInsideDocument = history?.location?.pathname?.includes('/pdf');

  const { isLoading, title, chunkHighlights } = useEvidenceHit({
    documentHitUrl,
    options: { enabled: !isInsideDocument },
  });

  const handleClick = () =>
    onEvidenceItemClick
      ? onEvidenceItemClick()
      : onChunkClick?.(defined(chunkHighlights, 'EvidenceHit.chunkHighlights'));

  const preparedLabel = useMemo(() => Parser.prepareLabel(label), [label]);

  const disabled = isExist(onEvidenceItemClick)
    ? false
    : !chunkHighlights?.length;

  const tooltip = disabled
    ? t('chat.evidence.notClickable')
    : t('chat.evidence.clickable');

  return (
    <Tooltip
      title={tooltip}
      placement="left"
      data-testid="EvidenceItem-Tooltip"
    >
      <div>
        <EvidenceContent
          title={title}
          label={preparedLabel}
          evidence={evidence}
          disabled={disabled}
          isLoading={isLoading}
          isInsideDocument={isInsideDocument}
          onClick={handleClick}
        />
      </div>
    </Tooltip>
  );
};
