import { SERVICE_URL } from 'api/apiConfig';
import axiosInstance from 'api/axiosInstance';
import { Evidence } from 'api/chatApi/chatApi.types';
import { AxiosResponse } from 'axios';
import { isExist } from 'common/utils/assert';
import { deserializeSearchHit } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { useEffect, useState } from 'react';

interface UseAllEvidenceHitsProps {
  evidences: Evidence[];
}

export const useAllEvidenceHits = ({ evidences }: UseAllEvidenceHitsProps) => {
  const [docsData, setDocsData] = useState<RetrievalUnitData[]>([]);

  const getDocumentData = async (
    documentHitUrl: string
  ): Promise<AxiosResponse<RetrievalUnitData | null>> => {
    // TODO: Chang it to use API from vendors
    const response = await axiosInstance.get(`${SERVICE_URL}${documentHitUrl}`);
    const { data, ...rest } = response;
    const hit = data.hits[0];
    return {
      ...rest,
      data: hit ? deserializeSearchHit(hit) : null,
    };
  };

  useEffect(() => {
    const fetchHits = async () => {
      const hits = await Promise.all(
        evidences.map((evidence) =>
          evidence.documentHitUrl
            ? getDocumentData(evidence.documentHitUrl)
            : Promise.resolve(null)
        )
      );

      const data = hits
        .filter((hit) => isExist(hit) && isExist(hit.data))
        .map((hit) => hit!.data);

      setDocsData(data as RetrievalUnitData[]);
    };

    void fetchHits();
  }, [evidences]);

  return { docsData };
};
