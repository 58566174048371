import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { defined } from 'common/utils/assert';

enum ErrorType {
  FatalError = 'FatalError',
}

interface Props<T, R> {
  url: string;
  payload: T;
  onMessage: (data: R) => void;
  onFinish?: () => void;
  onError?: (error: Error) => void;
  abortController?: AbortController;
}

export const sseRequest = async <T, R>({
  url,
  payload,
  onMessage,
  onError,
  abortController,
  onFinish,
}: Props<T, R>) => {
  await fetchEventSource(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: REMOVE IT BEFORE MERGING !!!
      'x-auth': defined(localStorage.getItem('AUTH_TOKEN'), 'AUTH_TOKEN'),
    },
    body: JSON.stringify(payload),
    signal: abortController?.signal,

    async onopen(response) {
      const contentType = response.headers.get('content-type');
      if (
        response.ok &&
        contentType &&
        [EventStreamContentType, 'application/octet-stream'].includes(
          contentType
        )
      ) {
        return; // everything's good
      }

      throw new Error(ErrorType.FatalError);
    },

    onmessage(message) {
      if (message.event?.startsWith(ErrorType.FatalError)) {
        throw new Error(`${ErrorType.FatalError}: ${message.data}`);
      }

      if (!message.data) {
        return;
      }

      try {
        onMessage?.(JSON.parse(message.data) as R);
      } catch (error) {
        throw new Error(
          `${ErrorType.FatalError}: ${(error as Error)?.message}`
        );
      }
    },

    onclose() {
      onFinish?.();
    },

    onerror(error: Error) {
      if (error.message?.startsWith(ErrorType.FatalError)) {
        onError?.(error);
        throw error;
      }
    },
  });
};
