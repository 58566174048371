import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const useSse = () => {
  const history = useHistory();
  const isInsideDocument = history?.location?.pathname?.includes('/pdf');

  const isSseAvailable = useMemo(
    () =>
      isInsideDocument &&
      isFeatureEnabled('ff-chat-streaming') &&
      // TODO: USE REAL TENANT SETTINGS
      !!localStorage.getItem('AUTH_TOKEN'),
    [isInsideDocument]
  );

  return { isSseAvailable };
};
