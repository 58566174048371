import React, { FC, ReactNode } from 'react';
import { FunctionCallProperties } from 'api/chatApi/chatApi.types';
import { Message } from '../Message';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useTranslation } from 'react-i18next';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import NoteAnnotationHighlightItem from 'common/components/Notes/NoteAnnotationHighlightItem';
import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

export interface ConversationMessageProps {
  sender: ChatMessageSenderEnum;
  functionCallRequest?: FunctionCallProperties;
  initials?: string;
  extra?: ReactNode;
  content: string;
  image?: string | null;
}

export const ConversationMessage: FC<ConversationMessageProps> = ({
  sender,
  initials,
  extra,
  content,
  functionCallRequest,
  image,
}) => {
  const { t } = useTranslation(['chat']);

  const { noteId } = useLocationParams('noteId');
  const { push } = useNavigationWithState();

  const openSuggestedDocsModal = () => {
    if (!functionCallRequest) {
      throw new Error('Function call request is required');
    }
    push(`?tab=chat&noteId=${noteId}&fc=${functionCallRequest.name}`, {
      functionCallParams: JSON.stringify(functionCallRequest.params),
    });
  };

  return (
    <Message
      sender={sender}
      initials={initials}
      isContent={!!content}
      extra={extra}
      button={
        functionCallRequest ? (
          <TrackedButton
            onClick={openSuggestedDocsModal}
            eventName={TrackEventName.TagOnboardingSuggestedDocsClicked}
          >
            {t(`message.functionButton.label`)}
          </TrackedButton>
        ) : null
      }
    >
      <MarkdownText>{content}</MarkdownText>
      {image && (
        <NoteAnnotationHighlightItem
          contentType={'image'}
          contentBody={image}
        />
      )}
    </Message>
  );
};
