import {
  ChatApi,
  ChatResponseForm,
  Configuration,
} from '@zarn/vendor/dist/search';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import AxiosInstance from '../axiosInstance';
import { AxiosResponse } from 'axios';
import { ChatResponse } from './chatApi.types';
import { deserializeChatResponse } from './chatApi.utils';

export const chatApi = new ChatApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const requestChatAnswer = async (
  chatResponseForm: ChatResponseForm,
  tenant: string,
  indexCluster?: string
): Promise<AxiosResponse<ChatResponse>> => {
  const { data, ...response } = await chatApi.respond({
    chatResponseForm,
    requesterUuid: '',
    userRoles: '',
    tenant,
    indexCluster,
  });

  return { ...response, data: deserializeChatResponse(data) };
};
