import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Divider, Tooltip } from '@mui/material';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { Evidence } from 'api/chatApi/chatApi.types';
import { EvidenceItem } from './EvidenceItem/EvidenceItem';
import { useTranslation } from 'react-i18next';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

interface EvidenceListProps {
  items: Evidence[];
  onEvidenceChunkClick?: (chunks: ChunkHighlight[]) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  isEvidenceDialog?: boolean;
}

export const EvidenceList: FC<EvidenceListProps> = ({
  items,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  isEvidenceDialog,
}) => {
  const { t } = useTranslation('common');
  const [isExpanded, setIsExpanded] = useState(false);
  const evidenceRef = useRef<HTMLDivElement | null>(null);

  const toggleEvidence = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (evidenceRef.current) {
      setTimeout(
        () => evidenceRef.current?.scrollIntoView({ behavior: 'smooth' }),
        500
      );
    }
  }, [isExpanded]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '0.25rem',
        }}
      >
        <Tooltip title={t('chat.evidence.tooltip')}>
          <TrackedIconButton
            size="small"
            aria-label="show evidence"
            onClick={toggleEvidence}
            eventName={TrackEventName.ChatEvidenceClicked}
          >
            <LibraryBooksIcon />
          </TrackedIconButton>
        </Tooltip>
      </Box>
      {!isEvidenceDialog && (
        <Collapse unmountOnExit timeout={400} in={isExpanded}>
          <Divider />
          <div ref={evidenceRef}>
            {items.map((item) => (
              <EvidenceItem
                key={item.anchorText}
                documentHitUrl={item.documentHitUrl}
                label={item.label}
                evidence={item.textExtract}
                onChunkClick={onEvidenceChunkClick}
                onEvidenceItemClick={
                  onEvidenceItemClick
                    ? () => onEvidenceItemClick?.(item)
                    : undefined
                }
              />
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
};
