import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Conversation } from '../Chat.types';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { Evidence } from 'api/chatApi/chatApi.types';
import { ConversationMessageItem } from './ConversationMessageItem';

export interface ConversationListProps {
  conversation: Conversation;
  initials?: string;
  onEvidenceChunkClick?: (chunks: ChunkHighlight[]) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  isEvidenceDialog?: boolean;
}

export const ConversationList: FC<ConversationListProps> = ({
  conversation,
  initials,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  isEvidenceDialog,
}) => (
  <Box data-testid="ConversationList">
    {conversation.messages.map((message, index) => (
      <ConversationMessageItem
        message={message}
        initials={initials}
        onEvidenceChunkClick={onEvidenceChunkClick}
        onEvidenceItemClick={onEvidenceItemClick}
        isEvidenceDialog={isEvidenceDialog}
        key={`message-${index}`}
      />
    ))}
  </Box>
);
