import React, { FC, useMemo } from 'react';
import { ConversationMessage } from './ConversationMessage';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { ChatMessageElement, Evidence } from 'api/chatApi/chatApi.types';
import { Parser } from '../hooks/useConversation/parsers/Parser';
import { EvidenceButtonWithDialog } from '../Evidence/EvidenceButtonWithDialog';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import NoteAnnotationHighlightItem from 'common/components/Notes/NoteAnnotationHighlightItem';
import { EvidenceList } from '../Evidence/EvidenceList';

interface Props {
  message: ChatMessageElement;
  initials?: string;
  onEvidenceChunkClick?: (chunks: ChunkHighlight[]) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  isEvidenceDialog?: boolean;
}

export const ConversationMessageItem: FC<Props> = ({
  message,
  initials,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  isEvidenceDialog,
}) => {
  const content = useMemo(() => Parser.prepareMessage(message), [message]);

  return (
    <ConversationMessage
      {...message}
      content={content}
      initials={initials}
      extra={
        message.evidences ? (
          isEvidenceDialog ? (
            <EvidenceButtonWithDialog
              items={message.evidences}
              answerContent={
                <>
                  <MarkdownText>{content}</MarkdownText>
                  {message.image && (
                    <NoteAnnotationHighlightItem
                      contentType={'image'}
                      contentBody={message.image}
                    />
                  )}
                </>
              }
            />
          ) : (
            <EvidenceList
              items={message.evidences}
              onEvidenceChunkClick={onEvidenceChunkClick}
              onEvidenceItemClick={onEvidenceItemClick}
              isEvidenceDialog={isEvidenceDialog}
            />
          )
        ) : null
      }
    />
  );
};
