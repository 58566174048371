import React, { FC } from 'react';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { EvidenceTitle } from './EvidenceTitle';
import { HtmlContent } from 'common/components/HtmlContent/HtmlContent';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  disabled: boolean;
  isInsideDocument: boolean;
  title: string;
  label: string;
  isLoading: boolean;
  evidence: string;
}

export const EvidenceContent: FC<Props> = ({
  onClick,
  disabled,
  isInsideDocument,
  title,
  label,
  isLoading,
  evidence,
}) => {
  const { t } = useTranslation('common');

  return (
    <Card
      sx={{ padding: '0.5rem', boxShadow: 'none' }}
      data-testid="EvidenceContent"
    >
      <CardActionArea onClick={onClick} disabled={disabled} component="div">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isInsideDocument ? (
            <>
              <Typography variant="body2">{label}</Typography>
              <EvidenceTitle title={title} isLoading={isLoading} />
            </>
          ) : null}
        </Box>
        <HtmlContent
          variant="body2"
          content={`${isInsideDocument ? label : ''} ${t(
            'chat.evidence.passage.prefix'
          )} ${evidence}`}
        />
      </CardActionArea>
    </Card>
  );
};
